import { useState, useEffect } from 'react'
import axios from 'axios'

import brasao from '../../../assets/brasao.png'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'

const currentYear = new Date().getFullYear()

const DfdExtraView = () => {
    const [dados, setDados] = useState({})
    const id = window.location.pathname.slice(14)
    const [cmd, setCmd] = useState()

    useEffect(() => {
        axios(`/api/dfdExtra/getDfdsById/${id}`)
            .then(res => setDados(res.data))
            .catch(error => alert(error.response.data.message))
    }, [])

    useEffect(() => {
        const url = '/api/orcamento/getCmdAss'
        axios.post(url, { regional: localStorage.regionalUser })
            .then(res => setCmd(res.data))
            .catch(error => alert(error.response.data.message))
    }, [])

    return (
        <>
            <div style={{ textAlign: "center", marginTop: "4vh", fontWeight: "600", marginBottom: "5vh" }}>
                <img src={brasao} style={{ width: "8vw" }} />
                <div>ESTADO DA PARAÍBA</div>
                <div>SECRETARIA DE SEGURANÇA E DEFESA SOCIAL</div>
                <div>CORPO DE BOMBEIROS MILITAR DA PARAÍBA</div>
            </div>
            <div style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", marginBottom: "4vh" }}>
                DOCUMENTO DE FORMALIZAÇÃO DE DEMANDA – DFD (EXTRA)
            </div>
            <div style={{
                border: "1px solid black", width: "98vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    INFORMAÇÕES DO NÍVEL SETORIAL
                </div>
                <div style={{
                    borderBottom: "1px solid black",
                    padding: "5px 0 5px 5px"
                }}><b>NÍVEL SETORIAL: </b>{RenderNivelSetorial()}</div>
                <div style={{ borderBottom: "1px solid black", display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>Bombeiro Militar  reponsável pelo Planejamento e esclarecimentos da Demanda: </b>
                        {localStorage.nome}
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Matrícula CBMPB: </b> {localStorage.numeroMatricula}
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>E-mail: </b> {localStorage.email}
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Contato: </b> {localStorage.contato}
                    </div>
                </div>
            </div>

            <div style={{
                border: "1px solid black", width: "98vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    INFORMAÇÕES DA NECESSIDADE DE CONTRATAÇÃO
                </div>
                <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                    <div style={{ textAlign: "center" }}><b>Necessidade / Justificativa técnica da contratação para itens:</b></div>
                    <br />
                    {dados?.justificativa}
                </b></div>
                <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                    <div style={{ textAlign: "center" }}><b>Resultados Pretendidos:</b></div>
                    <br />
                    {dados?.resultados}
                </b></div>
                {dados?.boolVinculacao === "Sim" ? <>
                    <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                        <div style={{ textAlign: "center" }}><b>Vinculação:</b></div>
                        <br />
                        {dados?.vinculacao}
                    </b></div>
                </> : <></>}
                <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                    <div style={{ textAlign: "center" }}><b>Justificativa da não inclusão no PCA:</b></div>
                    <br />
                    {dados?.justificativaInclusao}
                </b></div>
                <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                    <div style={{ textAlign: "center" }}><b>Sugestão de item do demandante a ser substituído:</b></div>
                    <br />
                    {dados?.sugestaoSubstituido}
                </b></div>
                <table className="tableDfd">
                    <thead>
                        <tr>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "3vw" }}>ITEM</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "4vw" }}>CÓDIGO</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "8vw" }}>OBJETO</th>
                            <th rowSpan="2" className="thDfdGeral" style={{
                                width: "20vw"
                            }}>DESCRIÇÃO BASE DE REFERÊNCIA</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "5vw" }}>UND. DE MEDIDA</th>
                            <th className="thDfdGeral" colSpan="3" style={{ width: "9vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "5vw" }}>QTD. ESTIMADA</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>VALOR ESTIMADO UNITÁRIO</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "10vw" }}>COMPROMETIMENTO ORÇAMENTÁRIO LOA 2025</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "9vw" }}>DATA PRETENDIDA / CALENDÁRIO DE CONTRATAÇÃO</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>GRAU DE PRIORIDADE</th>
                        </tr>
                        <tr>
                            <th className="thDfdGeral">{currentYear - 3}</th>
                            <th className="thDfdGeral">{currentYear - 2}</th>
                            <th className="thDfdGeral">{currentYear - 1}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dados.itens && dados.itens.map((el, index) => (
                            <tr key={`objeto${index}`}>
                                <td className="tdDfdGeral">{index + 1}</td>
                                <td className="tdDfdGeral">{el?.codCentral || "Sem correspondência no SGC"}</td>
                                <td className="tdDfdGeral">{el.objeto}</td>
                                <td className="tdDfdGeral">{el.descricao}</td>
                                <td className="tdDfdGeral">{el.und}</td>
                                <td className="tdDfdGeral">{el.ano1}</td>
                                <td className="tdDfdGeral">{el.ano2}</td>
                                <td className="tdDfdGeral">{el.ano3}</td>
                                <td className="tdDfdGeral">{el.qtdEstimada}</td>
                                <td className="tdDfdGeral">{formatCurrency(el.valorEstimado)}</td>
                                <td className="tdDfdGeral">{formatCurrency(el.qtdEstimada * el.valorEstimado)}</td>
                                <td className="tdDfdGeral">{el.entrega}</td>
                                <td className="tdDfdGeral">{el.prioridade}</td>
                            </tr>
                        ))}
                    </tbody>
                    <thead>
                        <tr>
                            <th colSpan="10" className="thDfdGeral" style={{
                                backgroundColor: "#f9ff06", color: "black", textAlign: "left"
                            }}>
                                VALOR TOTAL DO DOCUMENTO DE FORMALIZAÇÃO DA DEMANDA
                            </th>
                            <th colSpan="3" className="thDfdGeral" style={{
                                backgroundColor: "#f9ff06", color: "black", textAlign: "left"
                            }}>
                                {/* {RenderSomaTotal(tabelas[obj].items, obj)} */}
                            </th>
                        </tr>
                    </thead>
                </table>

            </div>
            <div style={{
                border: "1px solid black", width: "98vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    COMANDANTE / CHEFE / RESPONSÁVEL PELA UNIDADE REQUISITANTE
                </div>
                <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}><br />
                    Certifico que a formalização da demanda se faz necessária pelos motivos expostos na justificativa da área do presente documento em atendimento a Lei nº 14.133/2021, bem como na excepcionalidade prevista no Art. 13. do DECRETO Nº 44.639, de 22 de dezembro de 2023.
                </div><br />
                <div style={{ textAlign: "right", padding: "5px", fontWeight: "320" }}><br />
                    João Pessoa, datado via PBDOC.
                </div><br />
                <div style={{ textAlign: "center", padding: "5px", fontWeight: "420" }}><br />
                    {cmd !== undefined ? (
                        <div style={{ textAlign: "center", marginTop: "8vh" }}><b>
                            <br />
                            {cmd.nome} - {cmd.posto}<br />
                            {cmd.numeroMatricula} - {cmd.regional}
                        </b></div>
                    ) : (<></>)}
                    Assinado e datado via PBDOC
                </div><br />
            </div>
        </>
    )

    function RenderNivelSetorial() {
        if (localStorage.regionalUser === "1º CRBM" || localStorage.regionalUser === "2º CRBM" ||
            localStorage.regionalUser === "3º CRBM" || localStorage.regionalUser === "4º CRBM" ||
            localStorage.regionalUser === "5º CRBM" || localStorage.regionalUser === "CBMPB") {
            return localStorage.regionalUser
        } else return `Departamento (${localStorage.regionalUser})`
    }
}

export default DfdExtraView