import React, { useContext, useState } from 'react';
import { MyContext } from "../Biblioteca/MyContext"

const NavDal = (props) => {
    const { myState } = useContext(MyContext);
    const [display, setDisplay] = useState({
        dfdSead: "none",
        PCA: "none",
        buscas: "none",
        contratos: "none",
    })

    const linkStyle = {
        marginLeft: 'auto',
        marginRight: '1rem',
        color: myState === "light" ? 'black' : "#F9F9F9",
        fontWeight: 550,
        fontSize: "13px",
        cursor: 'pointer',
        textDecoration: 'none',
    }

    const toggleDisplay = (key) => {
        setDisplay(prev => ({
            ...prev,
            [key]: prev[key] === "none" ? "block" : "none"
        }))
    }

    if (window.matchMedia("(min-width:600px)").matches) {
        return (
            <div style={{ textAlign: "center", width: '100%' }}>
                <a href="/senha" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    SENHA
                </a>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>DFD SEAD</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/SelecionarModelo" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Novo DFD
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/BuscaDfdSead" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Buscar DFD
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>CONTRATOS</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/novoContrato" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Novo Contrato
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/BuscaContratos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Buscar Contratos
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/LogEdicoes" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Log de Edições
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>PCA</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/SetAnoGeral" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    PCA CBMPB
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/TabelaGestaoItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Execução PCA/DFDs
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>FERRAMENTAS</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/todosItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Buscar/Filtrar Itens
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/catsagrupados" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    CATs Agrupados
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/tabelasead" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Planilha de Itens(SEAD)
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/detalhamentoDespesaPca" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Detalhamento de Despesa
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/tabelaetp" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Tabela ETP
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/empenhos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Empenhos
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/pagamentos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Pagamentos
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <>
                <div className="mt-2">
                    <a href="/senha" style={{ ...props.estilo, ...linkStyle }}>
                        SENHA
                    </a>
                </div>
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('dfdSead')}>
                        DFD SEAD
                    </a>
                </div>
                <div className="mt-2 ms-2" style={{ display: display.pca2024 }}>
                    <div className='mt-2'>
                        <a href="/SelecionarModelo" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Novo DFD
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/BuscaDfdSead" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Buscar DFD
                        </a>
                    </div>
                </div>
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('contratos')}>
                        CONTRATOS
                    </a>
                </div>
                <div className="mt-2 ms-2" style={{ display: display.pca2024 }}>
                    <div className='mt-2'>
                        <a href="/NovoContrato" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Novo Contrato
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/BuscaContratos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Buscar Contratos
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/LogEdicoes" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Log de Edições
                        </a>
                    </div>
                </div>
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('PCA')}>
                        PCA
                    </a>
                </div>
                <div className="mt-2 ms-2" style={{ display: display.pca2024 }}>
                    <div className='mt-2'>
                        <a href="/SetAnoGeral" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            PCA CBMPB
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/TabelaGestaoItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Execução PCA/DFDs
                        </a>
                    </div>
                </div>
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('buscas')}>
                        FERRAMENTAS
                    </a>
                    <div className="mt-2 ms-2" style={{ display: display.buscas }}>
                        <div className='mt-2'>
                            <a href="/todosItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Buscar/Filtrar Itens
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/catsagrupados" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                CATs Agrupados
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/tabelasead" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Planilha de Itens(SEAD)
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/detalhamentoDespesaPca" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Detalhamento de Despesa
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/tabelaetp" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Tabela ETP
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/empenhos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Empenhos
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/pagamentos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Pagamentos
                            </a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default NavDal