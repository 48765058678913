import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Select from '../../Biblioteca/Select'
import Input from '../../Biblioteca/Input'
import Botao from '../../Biblioteca/Botao'
import ModalPagamento from './ModalPagamentos'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'
import converterData from '../../Biblioteca/Funcoes/converterData'

const mediaWeb = window.matchMedia("(min-width:600px)").matches

function Pagamentos() {
    const [fonte, setFonte] = useState()
    const [pagamentos, setPagamentos] = useState([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [filtros, setFiltros] = useState([])
    const [loading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [pagamentoModal, setSelectedPagamentoId] = useState(null)

    const fetchPagamentos = async (page, fonte, filtros, setPagamentos, setTotalPages, setLoading) => {
        setLoading(true)
        try {
            const params = {
                page,
                fonte,
                filtros: filtros.map(filtro => ({ tipo: filtro.tipo, opcao: filtro.opcao }))
            }

            const response = await axios.get('/api/dal/getPagamentos', { params })

            setPagamentos(response.data.pagamentos)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.error('Erro ao buscar pagamentos:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (fonte) {
            fetchPagamentos(page, fonte, filtros, setPagamentos, setTotalPages, setLoading)
        }
    }, [page, fonte, filtros])

    const handleFonteChange = (e) => {
        setFonte(e.target.value)
        setPage(1)
    }

    const addQtdFiltros = (e) => {
        setFiltros(prevFiltros => {
            if (e.target.name === "mais") return [...prevFiltros, { tipo: "", opcao: "" }]
            return prevFiltros.length > 1 ? prevFiltros.slice(0, -1) : prevFiltros
        })
    }

    const updateFiltro = (index, tipo) => {
        const updatedFiltros = [...filtros]
        updatedFiltros[index] = { ...updatedFiltros[index], tipo, opcao: "" }
        setFiltros(updatedFiltros)
    }

    let debounceTimeout

    const updateFiltroOpcao = (index, opcao) => {
        clearTimeout(debounceTimeout)

        debounceTimeout = setTimeout(() => {
            const updatedFiltros = [...filtros]
            updatedFiltros[index].opcao = opcao
            setFiltros(updatedFiltros)
        }, 800)
    }

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1)
        }
    }

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const handleDoubleClick = (pagamento) => {
        setSelectedPagamentoId(pagamento)
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
        setSelectedPagamentoId(null)
    }

    return (
        <div>
            <div style={{ margin: "1vw 0 0 1vw" }}>
                <Select texto="Fonte" nome="fonte" valor={fonte}
                    funcao={handleFonteChange} widthWeb="26vw" widthMobile="80vw"
                    options={["CBM", "FUNESBOM"]} />
            </div>

            <div style={{ margin: "1vw 0 0 1vw" }}>
                <button style={{
                    width: mediaWeb ? "4vw" : "10vw",
                    backgroundColor: "#E90303", color: "#FFFF",
                    border: "none", borderRadius: "5px"
                }}
                    name="menos"
                    onClick={addQtdFiltros}>-</button>
                <input style={{
                    marginLeft: "0.5vw", marginRight: "0.5vw", width: mediaWeb ? "4vw" : "10vw",
                    borderRadius: "5px", backgroundColor: "#00000000", textAlign: "center"
                }} value={filtros.length} disabled />
                <button style={{
                    width: mediaWeb ? "4vw" : "10vw", marginRight: "0.5vw",
                    backgroundColor: "#42A908", color: "#FFFF",
                    border: "none", borderRadius: "5px"
                }}
                    name="mais"
                    onClick={addQtdFiltros}>+</button>

                {filtros.map((filtro, index) => (
                    <div key={index}>
                        <Select texto="Filtro" nome="filtro" valor={filtro.tipo}
                            funcao={(e) => updateFiltro(index, e.target.value)}
                            widthWeb="26vw" widthMobile="80vw"
                            options={[
                                "Nº PAGAMENTO", "EMPENHO", "HISTÓRICO",
                                "CNPJ/CPF CREDOR", "CREDOR"
                            ]} />
                        <Input texto="Opção" nome="filtroOpcao" valor={filtro.opcao}
                            funcao={(e) => updateFiltroOpcao(index, e.target.value)}
                            widthWeb="26vw" widthMobile="80vw" />
                    </div>
                ))}
            </div>

            {loading ? (
                <p>Carregando...</p>
            ) : (
                <div style={{ position: mediaWeb ? "relative" : null, overflowX: mediaWeb ? null : "auto" }}>
                    <table className="tableDfd">
                        <thead>
                            <tr>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Nº Pagamento</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Valor Bruto</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Descontos</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Valor Líquido</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Valor Anulado</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Valor Pago Total</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Data</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Unidade Gestora</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Sigla Administração</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Unidade Pagadora</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Tipo Despesa</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Empenho</th>
                                <th style={{ width: mediaWeb ? "30vw" : "60vw" }}>Histórico</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>CNPJ/CPF Credor</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Nome Credor</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Banco</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Agência</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Conta Corrente</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Cheque Nº</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pagamentos.map((pagamento, index) => (
                                <tr key={index} onDoubleClick={() => handleDoubleClick(pagamento)}>
                                    <td>{pagamento.apNumero}</td>
                                    <td>{formatCurrency(pagamento.valorBruto)}</td>
                                    <td>{formatCurrency(pagamento.descontos)}</td>
                                    <td>{formatCurrency(pagamento.valorLiquido)}</td>
                                    <td>{formatCurrency(pagamento.valorAnulado)}</td>
                                    <td>{formatCurrency(pagamento.valorPagoTotal)}</td>
                                    <td>{converterData(pagamento.data.slice(0, 10))}</td>
                                    <td>{pagamento.unidadeGestora}</td>
                                    <td>{pagamento.siglaAdministracao}</td>
                                    <td>{pagamento.unidadePagadora}</td>
                                    <td>{pagamento.tipoDespesa}</td>
                                    <td>{pagamento.empenho}</td>
                                    <td>{pagamento.historico}</td>
                                    <td>{pagamento.cnpjCpfCredor}</td>
                                    <td>{pagamento.nomeCredor}</td>
                                    <td>{pagamento.banco}</td>
                                    <td>{pagamento.agencia}</td>
                                    <td>{pagamento.contaCorrente}</td>
                                    <td>{pagamento.chequeNumero}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <ModalPagamento
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                pagamento={pagamentoModal}
            />

            <div>
                <Botao texto="Anterior" widthWeb="12vw" widthMobile="28vw" display="inline" cor="rgb(51, 52, 143)"
                    funcao={handlePreviousPage} />
                <span>Página {page} de {totalPages}</span>
                <Botao texto="Próxima" widthWeb="12vw" widthMobile="28vw" display="inline" cor="rgb(51, 52, 143)" marginL="1vw"
                    funcao={handleNextPage} />
            </div>
        </div>
    )
}

export default Pagamentos
