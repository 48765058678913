import React, { useContext, useState, useEffect } from 'react'
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios'

import Select from '../Biblioteca/Select'
import Botao from '../Biblioteca/Botao'
import enviar from '../Biblioteca/Funcoes/enviar'

const NavGeral = (props) => {
    const { myState } = useContext(MyContext);
    const [display, setDisplay] = useState({
        pca2024: "none",
        pca2025: "none",
        buscas: "none",
        dfdSead: "none",
        contratos: "none",
        dfdExtra: "none"
    })

    const linkStyle = {
        marginLeft: 'auto',
        marginRight: '1rem',
        color: myState === "light" ? 'black' : "#F9F9F9",
        fontWeight: 550,
        fontSize: "13px",
        cursor: 'pointer',
        textDecoration: 'none',
    }

    const toggleDisplay = (key) => {
        setDisplay(prev => ({
            ...prev,
            [key]: prev[key] === "none" ? "block" : "none"
        }))
    }

    const [dadosUser, setDadosUser] = useState({ fonte: "", regional: "" })
    const [keyDiv, setKey] = useState(0)
    useEffect(() => {
        axios('/api/user/getFonteRegional')
            .then(res => {
                setDadosUser({ ...res.data, userId: localStorage.id })
                setKey(prevKey => prevKey + 1)
            })
            .catch(error => console.log(error))
    }, [])

    const updateField = (e) => {
        const { value, name } = e.target
        const tempState = { ...dadosUser }
        tempState[name] = value
        setDadosUser(tempState)
    }

    if (window.matchMedia("(min-width:600px)").matches) {
        return (
            <div style={{ textAlign: "center", width: '100%' }}>
                <a href="/senha" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    SENHA
                </a>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>PCA 2024</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/DFDsSelecionaveis" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Justificativa de QTD
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/tabelaPreenchimentoCentral" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Central de Compras
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/detalhamentoDespesa" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Detalhamento de Despesa
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/tabelaCod" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Inserção de Códigos
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/novoDed" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Novo DED
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/pesquisaDeds" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Buscar DED
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>PCA</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/solicitarItem" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Solicitar Item
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/aprovarItem" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Aprovar/Reprovar Itens
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/NovoDfd" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Novo DFD
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/SetAno" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Impressão DFD
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/SetAnoGeral" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Impressão DFD Geral
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/EscolhaItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Editar PCA
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/TabelaGestaoItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Execução PCA/DFDs
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>FERRAMENTAS</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/todosItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Buscar/Filtrar Itens
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/cpldal" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Editar Itens
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/UpdatedItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Log Itens Editados
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/catsagrupados" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    CATs Agrupados
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/tabelasead" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Planilha de Itens(SEAD)
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/detalhamentoDespesaPca" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Detalhamento de Despesa
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/tabelaetp" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Tabela ETP
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/bloquearliberardfd" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Bloquear/Liberar DFD
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/user" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Criar/Editar Usuário
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/empenhos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Empenhos
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/pagamentos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Pagamentos
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>DFD SEAD</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/SelecionarModelo" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Novo DFD
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/BuscaDfdSead" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Buscar DFD
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>CONTRATOS</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/novoContrato" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Novo Contrato
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/BuscaContratos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Buscar Contratos
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/LogEdicoes" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Log de Edições
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>DFD EXTRA</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/DfdExtra" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Novo DFD
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/GetDfdExtra" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Visualizar DFDs Extras
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="/dashboard" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    DASHBOARD
                </a>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>SETOR/FONTE</span>
                    <div className="caixa1">
                        <div className="caixao" style={{ padding: "5px" }}>
                            <div className='mt-2'>
                                <Select key={keyDiv} texto="Setor" nome="regional" valor={dadosUser.regional}
                                    funcao={updateField} widthWeb="16vw" widthMobile="80vw"
                                    options={["CBMPB", "1º CRBM", "2º CRBM", "3º CRBM", "4º CRBM", "Operações com Cães",
                                        "Projetos Sociais", "DAT", "Operações Aéreas", "Diretoria de Ensino, Instrução e Pesquisa",
                                        "Tecnologia da Informação", "Mergulho Autônomo", "DEFID", "Banda de Música",
                                        "Gabinete Médico", "Ajudância", "Gabinete Odontológico", "Núcleo Estratégico"]} />
                            </div>
                            <div className='mt-2'>
                                <Select key={keyDiv} texto="Fonte" nome="fonte" valor={dadosUser.fonte}
                                    funcao={updateField} widthWeb="16vw" widthMobile="80vw"
                                    options={["CBM", "FUNESBOM"]} />
                            </div>
                            <div className='mt-2'>
                                <Botao texto="Salvar"
                                    funcao={() => enviar('/api/pca/updateUserInfo', dadosUser, localStorage.setItem("regionalUser", dadosUser.regional))} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <>
                <div className="mt-2">
                    <a href="/senha" style={{ ...props.estilo, ...linkStyle }}>
                        SENHA
                    </a>
                </div>
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('pca2024')}>
                        PCA 2024
                    </a>
                </div>
                <div className="mt-2 ms-2" style={{ display: display.pca2024 }}>
                    <div className='mt-2'>
                        <a href="/DFDsSelecionaveis" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Justificativa de QTD
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/tabelaPreenchimentoCentral" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Central de Compras
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/detalhamentoDespesa" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Detalhamento de Despesa
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/tabelaCod" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Inserção de Códigos
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/novoDed" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Novo DED
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/pesquisaDeds" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Buscar DED
                        </a>
                    </div>
                </div>
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('pca2025')}>
                        PCA
                    </a>
                    <div className="mt-2 ms-2" style={{ display: display.pca2025 }}>
                        <div className='mt-2'>
                            <a href="/solicitarItem" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Solicitar Item
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/aprovarItem" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Aprovar/Reprovar Itens
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/NovoDfd" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Novo DFD
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/SetAno" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Impressão DFD
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/SetAnoGeral" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Impressão DFD Geral
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/EscolhaItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Editar PCA
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/TabelaGestaoItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Execução PCA/DFDs
                            </a>
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('buscas')}>
                        FERRAMENTAS
                    </a>
                    <div className="mt-2 ms-2" style={{ display: display.buscas }}>
                        <div className='mt-2'>
                            <a href="/todosItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Buscar/Filtrar Itens
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/cpldal" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Editar Itens
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/UpdatedItens" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Log Itens Editados
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/catsagrupados" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                CATs Agrupados
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/tabelasead" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Planilha de Itens(SEAD)
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/detalhamentoDespesaPca" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Detalhamento de Despesa
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/tabelaetp" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Tabela ETP
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/bloquearliberardfd" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Bloquear/Liberar DFD
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/user" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Criar/Editar Usuário
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/empenhos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Empenhos
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/pagamentos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                Pagamentos
                            </a>
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('dfdSead')}>
                        DFD SEAD
                    </a>
                </div>
                <div className="mt-2 ms-2" style={{ display: display.dfdSead }}>
                    <div className='mt-2'>
                        <a href="/SelecionarModelo" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Novo DFD
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/BuscaDfdSead" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Buscar DFD
                        </a>
                    </div>
                </div>
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('contratos')}>
                        CONTRATOS
                    </a>
                </div>
                <div className="mt-2 ms-2" style={{ display: display.contratos }}>
                    <div className='mt-2'>
                        <a href="/NovoContrato" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Novo Contrato
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/BuscaContratos" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Buscar Contratos
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/LogEdicoes" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Log de Edições
                        </a>
                    </div>
                </div>
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('dfdExtra')}>
                        DFD EXTRA
                    </a>
                </div>
                <div className="mt-2 ms-2" style={{ display: display.dfdExtra }}>
                    <div className='mt-2'>
                        <a href="/DfdExtra" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Novo DFD
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/GetDfdExtra" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Visualizar DFDs Extras
                        </a>
                    </div>
                </div>
                {/* <div className="mt-2">
                    <a href="/dashboard" style={{ ...props.estilo, ...linkStyle }}>
                        DASHBOARD PCA
                    </a>
                </div> */}
            </>
        )
    }
}

export default NavGeral